<template>
  <div class="home">
    <!-- banner -->
    <div class="banner">
      <img
        class="sampleReportImg"
        @click="exampleBtn"
        src="@/assets/image/btn.3af5623b.png"
        alt=""
      />
    </div>

    <!-- xx秒已购买 -->
    <div class="xxdw">
      <rollbuy></rollbuy>
    </div>

    <!-- 数据交互部分 -->
    <div class="frombox">
      <div class="fromdiv">
        <!-- 小喇叭通知 -->
        <div class="smallHorn">
          <van-notice-bar
            left-icon="volume-o"
            text=" 2356967人已深度排查，了解信用风险维护信用健康。"
          />
        </div>

        <!-- 输入手机号 -->
        <div class="fromphone" v-if="!twoShow">
          <img src="@/assets/image/userphone.png" alt="" />
          <input
            type="tel"
            placeholder="请输入手机号"
            maxlength="11"
            v-model="userPhone"
          />
        </div>

        <div class="fromphone" v-if="twoShow">
          <img src="@/assets/image/user.png" alt="" />
          <input
            type="text"
            placeholder="请输入姓名"
            maxlength="20"
            v-model="userName"
          />
        </div>

        <div class="fromphone" v-if="twoShow">
          <img src="@/assets/image/userid.png" alt="" />
          <input
            type="text"
            placeholder="请输入身份证号码"
            maxlength="18"
            v-model="userId"
          />
        </div>

        <!-- 协议1 -->
        <div class="agreement">
          <van-checkbox
            v-model="agreementChecked"
            icon-size="14px"
            shape="square"
          />
          <span style="color: 666; margin-left: 10px">我已同意并阅读</span>
          <span style="color: #4772fc" @click="gotoprotocol"
            >《报告查询服务协议》</span
          >
        </div>
        <!-- 协议2 -->
        <div class="agreement" v-if="bestowal != -1 && bestowalContent">
          <van-checkbox
            v-model="agreementChecked2"
            icon-size="14px"
            shape="square"
          />
          <!-- <span v-if="bestowal == 2" style="color: 666; margin-left: 10px">{{
            content
          }}</span> -->
          <!--  v-if="
              bestowal == 0 || bestowal == 1 || bestowal == 3 || bestowal == 4
            " -->
          <div
            class="twoAgreement"
            style="color: 666; margin-left: 10px"
            v-if="bestowalContent"
          >
            {{ bestowalContent }}
            <span
              style="color: #4772fc"
              @click="insuranceProtocol"
              v-if="agreementName"
            >
              《{{ agreementName }}》</span
            >
          </div>
        </div>
        <!-- 确认按钮-->
        <div class="okbtn" v-if="!twoShow">
          <van-button type="info" @click="clickbtn1" block>{{
            textBtn
          }}</van-button>
          <div class="yellowdiv" @click="clickbtn1">83%用户已查</div>
          <img
            class="dwimg"
            src="@/assets/image/click.gif"
            alt=""
            @click="clickbtn1"
          />
        </div>
        <div class="okbtn" v-if="twoShow">
          <van-button type="info" @click="clickbtn2" block>{{
            textBtn
          }}</van-button>
          <div class="yellowdiv" @click="clickbtn2">83%用户已查</div>
          <img
            class="dwimg"
            src="@/assets/image/click.gif"
            alt=""
            @click="clickbtn2"
          />
        </div>
      </div>
      <!-- 六个要素 -->
      <div class="imgboxsix">
        <img src="@/assets/image/superiority.png" alt="" />
      </div>

      <!-- 动态swipe -->
      <SwpItem></SwpItem>
      <!-- 底部备案信息 -->
      <div class="keepOnRecord">
        <p>(温馨提示:本报告非央行征信报告)</p>
        <p>
          严格按照相关法律法规及监管单位的操作要求,合法合规经营,若您对查询结果有异议,发起异议处理。
        </p>
        <p>客服电话：027-88386663</p>
        <a :href="link" style="color: white; text-decoration: underline"
          >关于我们</a
        >
        <p>武汉宏安法律咨询有限公司 版权所有 鄂ICP备2022000265号-2</p>
      </div>

      <!-- 右侧的 三个按钮 -->
      <div class="gdflex">
        <rightNavigation :showReport="navigationHidden"></rightNavigation>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getUserInfo, button } from "../api/getUserInfo";
import {
  NoticeBar,
  Icon,
  Button,
  Checkbox,
  CheckboxGroup,
  Swipe,
  SwipeItem,
  Toast,
} from "vant";

Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);

import SwpItem from "../components/SwpItem.vue";
import rightNavigation from "../components/rightNavigation.vue";
import rollbuy from "../components/rollbuy.vue";
export default {
  components: {
    SwpItem,
    rightNavigation,
    rollbuy,
  },
  name: "Home",
  data() {
    return {
      // 是否开启报告样例 (模板一不开启)~
      navigationHidden: {
        // 报告样例
        reportSample: {
          show: false,
          styleCss: "",
        },
        // 我的报告
        myReport: {
          show: true,
          styleCss: "",
        },
        // 客服
        service: {
          show: true,
          styleCss: "",
        },
        // 建议
        suggest: {
          show: true,
          styleCss: "",
        },
      },
      userPhone: null,
      userName: null,
      userId: null,
      // 是否勾选协议1
      agreementChecked: true,
      // 是否勾选协议2
      agreementChecked2: false,
      xxdd: [
        {
          img: require("../assets/image/avatar1.png"),
          text: "1分钟前有人已购买",
        },
        {
          img: require("../assets/image/avatar2.png"),
          text: "46s前有人已购买",
        },
        {
          img: require("../assets/image/avatar3.png"),
          text: "23s前有人已购买",
        },
        { img: require("../assets/image/avatar4.png"), text: "8s前有人已购买" },
      ],
      // 第二部状态
      twoShow: false,
      textBtn: "立即查询",
      bestowal: "", //赠险类型
      cid: null,
      link: "",
      bestowalContent: "本人自愿免费领取价值999元个人债务规划方案",
      agreementName: "",
      logidUrl: "",
      bd_vid: "",
      isStop: false,
    };
  },
  created() {
    localStorage.clear();
    this.cid = this.$route.params.cid;
    this.link = `https://www.whha666.com/inquiry/#/?cid=${this.cid}`;
    this.logidUrl = location.href;
    this.bd_vid = this.$route.query.bd_vid || this.getUrlCode().bd_vid || "";
    localStorage.setItem("bd_vid", this.bd_vid);
    localStorage.setItem("logidUrl", this.logidUrl);
    // console.log(this.logidUrl);
  },
  mounted() {
    this.buttonTxt({ cid: this.cid });
  },
  methods: {
    getUrlCode() {
      var url = location.search;
      // console.log(location.href);
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    // 获取按钮文案
    buttonTxt(data) {
      button(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.textBtn = res.data.data.buttonText || "立即查询";
            this.bestowal = res.data.data.bestowalType;
            this.bestowalContent = res.data.data.bestowalContent;
            this.agreementName = res.data.data.agreementName;
          } else {
            //按钮禁用
            this.isStop = true;
            this.navigationHidden = {
              reportSample: {
                show: false,
                styleCss: "",
              },
              // 我的报告
              myReport: {
                show: false,
                styleCss: "",
              },
              // 客服
              service: {
                show: true,
                styleCss: "",
              },
              // 建议
              suggest: {
                show: false,
                styleCss: "",
              },
            };
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    // 查看示例
    exampleBtn() {
      this.$router.push({
        path: "/example",
      });
    },
    // 立即查询 第一次
    clickbtn1() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!reg.test(this.userPhone)) {
        return Toast("请填写正确的手机号码");
      }
      if (!this.agreementChecked) {
        return Toast("请同意《报告查询服务协议》");
      }
      this.twoShow = true;
      return Toast("请输入姓名及身份证");
    },
    // 第二部提交数据
    clickbtn2() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      let Toast1 = Toast.loading({
        duration: 0,
        message: "查询中...",
        forbidClick: true,
      });
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let _IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
      var namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if (!namereg.test(this.userName) || !this.userName) {
        return Toast("请正确填写您的姓名");
      }
      if (!this.agreementChecked) {
        return Toast("请同意《报告查询服务协议》");
      }
      if (_IDRe18.test(this.userId) || _IDre15.test(this.userId)) {
        // 这里写逻辑
        let cdata = {
          username: this.userName,
          mobile: this.userPhone,
          idCard: this.userId,
          bestowal: this.agreementChecked2,
          cid: this.$route.params.cid,
        };

        getUserInfo(cdata)
          .then((res) => {
            if (res.data.code == 200) {
              Toast1.clear();
              // status true 正常用户 false 用户已拉黑
              if (res.data.data.status) {
                // orderNo 存在 24小时内已经购买过的 去详情
                if (res.data.data.orderNo == null) {
                  // 未支付的 去支付
                  this.$router.push({
                    path: "/toBePaid",
                    query: {
                      userId: res.data.data.userId,
                      cid: this.$route.params.cid,
                      idCard: this.userId,
                      userName: this.userName,
                    },
                  });
                } else {
                  this.$router.push({
                    path: "/prosperity",
                    query: {
                      orderNo: res.data.data.orderNo,
                      cid: this.cid,
                      templateId: 1,
                    },
                  });
                }
              } else {
                //  用户已拉黑
                Toast(res.data.msg);
              }
            } else {
              Toast(res.data.msg);
            }
          })
          .catch((err) => {
            Toast(err);
          });
      } else {
        return Toast("身份证填写有误");
      }
    },
    gotoprotocol() {
      this.$router.push({
        path: "/queryProtocol",
      });
    },
    insuranceProtocol() {
      this.$router.push({
        path: "/insuranceProtocol",
        query: {
          cid: this.cid,
        },
      });
    },
    userProtocol() {
      this.$router.push({
        path: "/userProtocol",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  max-width: 750px;
  height: auto;
  margin: 0 auto;
  position: relative;
}
.banner {
  width: 100%;
  min-height: 714px;
  background-image: url("../assets/image/banner_cz.png");
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background-size: contain !important;
  position: relative;
  .sampleReportImg {
    width: 336px;
    height: 90px;
    position: absolute;
    right: 46px;
    bottom: 30px;
  }
}
.frombox {
  width: 92%;
  min-height: 100px;
  background: url("../assets/image/backgrounp.png");
  // background: pink;
  background-repeat: repeat-y;
  background-size: contain;
  padding: 10px 4% 100px 4%;
  .fromdiv {
    padding: 20px 16px;
    min-height: 480px;
    background: #fff;
    border-radius: 4%;
    margin: 0 auto;
    box-sizing: border-box;
    .smallHorn {
      width: 92%;
      height: 60px;
      margin: 0 auto;
    }
    ::v-deep .van-notice-bar {
      width: 92%;
      height: 60px;
      border-radius: 30px !important;
      background: #fff6ef;
    }
    .fromphone {
      width: 92%;
      height: 72px;
      border-radius: 35px !important;
      margin: 0 auto;
      border: 1px solid #d2d2d2 !important;
      display: flex;
      padding: 0 16px;
      box-sizing: border-box;
      align-items: center;
      margin: 40px auto;
    }
    .fromphone > img {
      width: 48px;
      height: 48px;
    }
    .fromphone > input {
      width: 92%;
      height: 50px;
      border: none;
      font-size: 28px;
      margin-left: 20px;
    }
    /* 协议 */
    .agreement {
      width: 92%;
      min-height: 30px;
      margin: 20px auto;
      /* background: skyblue; */
      display: flex;
      align-items: center;
      font-size: 24px;
    }
    .agreement1 {
      width: 92%;
      display: flex;
      margin: 20px auto;
    }
    .twoAgreement {
      width: 92%;
      // min-height: 50px;
      text-align: left;
    }
    /* 确认按钮 */
    .okbtn {
      width: 92%;
      height: 78px;
      background: red;
      margin: 0 auto;
      margin-top: 40px;
      position: relative;
    }
    .okbtn .van-button {
      width: 100%;
      height: 100%;
    }
    .yellowdiv {
      width: 190px;
      height: 65px;
      line-height: 65px;
      background: linear-gradient(90deg, #ff8000, #ffb012);
      border-radius: 30px 30px 30px 0;
      border: 2px solid #fff;
      min-width: 108px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      position: absolute;
      top: -30px;
      right: 10px;
    }
    .dwimg {
      position: absolute;
      top: 0;
      right: 0;
      width: 132px;
      height: 132px;
    }
  }
}
.imgboxsix {
  width: 100%;
  height: auto;
  border-radius: 4%;
  margin-top: 30px;
  img {
    width: 100%;
  }
}
.xxdw {
  width: 400px;
  height: 80px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  left: 0;
}
::v-deep .van-notice-bar {
  background-color: rgba(red, green, blue, 0);
}
.gdflex {
  position: fixed;
  top: 50vh;
  right: 0;
}
.keepOnRecord {
  text-align: center;
  p {
    width: 100%;
    font-size: 26px;
    color: #fff;
    margin: 20px 0;
  }
}
</style>
