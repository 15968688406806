import request from '@/utils/request';

// 获取查询人信息 姓名 手机号 身份证
export const getUserInfo = (data) => {
  return request({
    method: 'post',
    url: '/reference-check/getUserInfo',
    data,
  });
};
// 支付宝pc网页支付
export const tradePCPay = (data) => {
  return request({
    method: 'post',
    url: `/reference-check/ali-pay/tradePCPay/${data.userId}/${data.cid}`,
    data,
  });
};
// 支付宝手机网页支付
export const tradeWapPay = (data) => {
  return request({
    method: 'post',
    url: `/reference-check/ali-pay/tradeWapPay/${data.userId}/${data.cid}`,
    data,
  });
};
// 微信 支付宝 付款接口 userId cid  payType = 1支付宝网页 2支付宝手机 3jsapi
export const tradePay = (data) => {
  return request({
    method: 'post',
    url: '/reference-check/tradePay',
    data,
  });
};

// 查询背调数据 userId
export const reference = (data) => {
  return request({
    method: 'get',
    url: `/reference-check/report/${data.orderNo}`,
  });
};

// 我的报告 post请求 searchValue
export const myReport = (data) => {
  return request({
    method: 'post',
    url: '/reference-check/myReport',
    data,
  });
};
// 获取按钮文案 /h5/reference-check/button/{cid}  get请求
export const button = (data) => {
  return request({
    method: 'get',
    url: `/reference-check/button/${data.cid}`,
  });
};
// 获取客服 /h5/reference-check/server/{type} 获取客服 get请求
export const server = (data) => {
  return request({
    method: 'get',
    url: `/reference-check/server/${data.type}`,
  });
};

// 投诉建议
export const complaint = (data) => {
  return request({
    method: 'post',
    url: `/reference-check/complaint/`,
    data,
  });
};

// 获取openid
export const openId = (data) => {
  return request({
    method: 'get',
    url: `/reference-check/openId?${data.code}`,
  });
};
// 获取支付方式受否隐藏
export const payWay = (data) => {
  return request({
    method: 'get',
    url: '/reference-check/payWay/' + data.cid,
  });
};
// 获取微信商户的appid 生成code码
export const appId = (data) => {
  return request({
    method: 'get',
    url: '/reference-check/appid',
  });
};

// 根据userid 查询 oderid 进入报告详情
export const latestOrderId = (data) => {
  return request({
    method: 'get',
    url: '/reference-check/latestOrderId/' + data.userId,
  });
};
// 查询订单状态
export const orderStatus = (data) => {
  return request({
    method: 'get',
    url: '/reference-check/orderStatus?orderNo=' + data.orderNo,
  });
};
// 支付之前生成订单编号
export const genOrderNo = (data) => {
  return request({
    method: 'post',
    url: '/reference-check/genOrderNo',
    data: data,
  });
};
