<template>
    <div >   
        <div class="swp">
            <p>听听买过的人怎么说</p>
            <div class="swpboxs">
                <div class="swpbox" >
                    <div class="swpbox1" v-for="(item,index) in xxdd" :key="index"  :style="swpCss">
                        <div class="swpbox1_l"><img :src="item.img" alt=""></div>
                        <div class="swpbox1_r">
                            <span class="span1">{{item.name}}</span>
                            <span class="span2">{{item.text}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"SwpItem",
    data(){
        return{
            xxdd:[
                {img:require("../assets/image/avatar1.png"),name:"黄*",text:'真心劝大家都查一下，排查自身风险很有必要'},
                {img:require("../assets/image/avatar2.png"),name:"胡*",text:'报告很全很详细，了解了自己的风险情况'},
                {img:require("../assets/image/avatar3.png"),name:"李*",text:'很方便就查到自己的报告，有两项风险'},
                {img:require("../assets/image/avatar4.png"),name:"曹*",text:'发现自己被冒名起诉了，赶紧联系相关部门...'},
                {img:require("../assets/image/avatar1.png"),name:"朱*",text:'看到自己的报告没有任何问题 安心'},
                {img:require("../assets/image/avatar1.png"),name:"黄*",text:'真心劝大家都查一下，排查自身风险很有必要'},
                {img:require("../assets/image/avatar2.png"),name:"胡*",text:'报告很全很详细，了解了自己的风险情况'},
                {img:require("../assets/image/avatar3.png"),name:"李*",text:'很方便就查到自己的报告，有两项风险'},
                {img:require("../assets/image/avatar4.png"),name:"曹*",text:'发现自己被冒名起诉了，赶紧联系相关部门...'},
            ],
            swpCss:null,
            b:2000
        }
    },
    mounted(){
        let a= 0
        setInterval(()=>{
            a+=1.332
            if(a>=7.99){
                a=0
                this.swpCss ="transition-duration:0ms;"
            }else{
                this.swpCss = `transition-duration: 500ms; transform: translateY(-${a}rem);`
            }
        },1500)
    },
    methods:{
        
    }
}
</script>

<style lang="less" scoped>
    .swp{
        width: 100%;
        height: 450px;
        background: #fff;
        margin-top:20px;
        border-radius: 35px;
        p{
            width: 100%;
            height: 100px;
            line-height: 100px;
            font-size: 32px;
            font-weight:600;
            // background: red;
            text-align: center;
        }
        .swpboxs{
            width: 100%;
            height: 300px;
            overflow: hidden;
        }
        .swpbox{
            width: 100%;
            height: 300px;
            // overflow: hidden;
            .swpbox1{
                height: 100px;
                display: flex;
                align-items: center;
                // background: red;
                padding: 0 24px ;
                .swpbox1_l{
                    img{
                        width: 80px;
                        height: 80px;
                        margin-right:50px;
                    }
                }
                .swpbox1_r{
                    display: flex;
                    flex-direction:column;
                    justify-content: center;
                    align-items: flex-start;
                    .span1{
                        font-size: 28px;
                    }
                    .span2{
                        font: 24px;
                    }
                }
            }
           
        }
    }
</style>