import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    // 加载页
    path: '/loading',
    name: 'loading',
    component: () => import('../views/loading.vue'),
  },
  {
    // 我的报告查询
    path: '/Reportquery',
    name: 'Reportquery',
    component: () => import('../views/Reportquery.vue'),
  },
  {
    // 测试页面路由
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
  },
  {
    // 样例报告
    path: '/example',
    name: 'Example',
    component: () => import('../views/example.vue'),
  },
  {
    // 待付款查看报告
    path: '/toBePaid',
    name: 'ToBePaid',
    component: () => import('../views/toBePaid.vue'),
  },
  {
    // 常见问题
    path: '/commonProblem',
    name: 'CommonProblem',
    component: () => import('../views/commonProblem.vue'),
  },
  {
    // 客服服务
    path: '/customerService',
    name: 'CustomerService',
    component: () => import('../views/customerService.vue'),
  },
  {
    // 取消的页面
    path: '/cancel',
    name: 'cancel',
    component: () => import('../views/cancel.vue'),
  },
  {
    // 成功的页面 有数据的
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue'),
  },
  {
    // 报告查询服务协议
    path: '/queryProtocol',
    name: 'queryProtocol',
    component: () => import('../views/queryProtocol.vue'),
  },
  {
    // 赠险协议
    path: '/insuranceProtocol',
    name: 'insuranceProtocol',
    component: () => import('../views/insuranceProtocol.vue'),
  },
  {
    // 赠险协议
    path: '/userProtocol',
    name: 'userProtocol',
    component: () => import('../views/userProtocol.vue'),
  },
  {
    // 债务咨询
    path: '/debtServe',
    name: 'debtServe',
    component: () => import('../views/debtServe.vue'),
  },
  {
    // 引导页
    path: '/wxpay',
    name: 'wxpsy',
    component: () => import('../views/wxpay.vue'),
  },
  {
    // 第三方支付引导页
    path: '/guidPay',
    name: 'guidPay',
    component: () => import('../views/guidPay.vue'),
  },
  //查询报告详情页(大数据报告)
  {
    path: '/prosperity',
    name: 'prosperity',
    component: () => import('../views/prosperity.vue'),
  },
  {
    path: '/home3',
    name: 'Home3',
    component: () => import('../views/Home3.vue'),
  },
  {
    path: '/home2',
    name: 'Home2',
    component: () => import('../views/Home2.vue'),
  },
  {
    path: '/:cid',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    component: () => import('../views/404.vue'),
  },
];
//防止路由重复点击而出现的报错信息
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
});

export default router;
