/*
 * @Author: 申馨
 * @Date: 2023-08-22 09:32:23
 * @LastEditors: 申馨
 * @LastEditTime: 2023-10-24 12:30:07
 * @Description: file content
 * @FilePath: \hongan-h5\src\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'lib-flexible';
import axios from 'axios';
Vue.prototype.$http = axios;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// 手机端调试插件;
// import Vconsole from 'vconsole';
// new Vconsole();

import filters from './utils/filter/filter';
// 这种方式可以将filters.js中的所有过滤器都注册
for (let key in filters) {
  Vue.filter(key, filters[key]);
}

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
