const filters = {
  xypCpl0001: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[0-9]';
      case 2:
        return '[9-14]';
      case 3:
        return '[14-很多]';
      case 4:
        return '';
      case 5:
        return '';
    }
  },
  xypCpl0002: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-5]';
      case 2:
        return '[5-9]';
      case 3:
        return '[9-14]';
      case 4:
        return '[14-17]';
      case 5:
        return '[17-很多]';
    }
  },
  xypCpl0007: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '1';
      case 2:
        return '2';
      case 3:
        return '3';
      case 4:
        return '[4-很多]';
      case 5:
        return '';
    }
  },
  xypCpl0008: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '1';
      case 2:
        return '[2-5]';
      case 3:
        return '[5-11]';
      case 4:
        return '[11-很多]';
      case 5:
        return '';
    }
  },
  xypCpl0014: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-2]';
      case 2:
        return '[2-10]';
      case 3:
        return '[10-34]';
      case 4:
        return '[34-60]';
      case 5:
        return '[60-很多]';
    }
  },
  xypCpl0015: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-3]';
      case 2:
        return '[3-5]';
      case 3:
        return '[5-10]';
      case 4:
        return '[10-很多]';
    }
  },
  xypCpl0045: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-220]';
      case 2:
        return '[220-450]';
      case 3:
        return '[450-520]';
      case 4:
        return '[520-很多天]';
      case 5:
        return '[]';
    }
  },
  xypCpl0046: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-5]';
      case 2:
        return '[5-25]';
      case 3:
        return '[25-很多天]';
      case 4:
        return '[]';
      case 5:
        return '[]';
    }
  },
  xypCpl0071: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '1';
      case 2:
        return '2';
      case 3:
        return '[4-很多]';
      case 4:
        return '[]';
      case 5:
        return '[]';
    }
  },
  xypCpl0072: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-1000]';
      case 2:
        return '[1000-2000]';
      case 3:
        return '[2000-3000]';
      case 4:
        return '[3000-5000]';
      case 5:
        return '[5000-7000]';
      case 6:
        return '[7000-11000]';
      case 7:
        return '[11000-很多]';
    }
  },
  xypT01abzzza: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-3000]';
      case 2:
        return '[3000-27000]';
      case 3:
        return '[27000-很多]';
      case 4:
        return '[]';
      case 5:
        return '[]';
    }
  },
  xypT01aczzaz: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-3000]';
      case 2:
        return '[3000-27000]';
      case 3:
        return '[27000-很多]';
      case 4:
        return '[]';
      case 5:
        return '[]';
    }
  },
  xypT01aczzbc: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-12000]';
      case 2:
        return '[12000-54000]';
      case 3:
        return '[54000-很多]';
      case 4:
        return '[]';
      case 5:
        return '[]';
    }
  },
  xypT01aczzzz: function (val) {
    switch (val) {
      case '':
        return '0';
      case 0:
        return '0';
      case 1:
        return '[1-45000]';
      case 2:
        return '[45000-115000]';
      case 3:
        return '[115000-很多]';
      case 4:
        return '';
      case 5:
        return '';
    }
  },
};

export default filters;
