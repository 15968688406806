<template>
  <div class="threeItme">
    <div
      class="itembox four"
      @click="sampleReport"
      :style="showReport.reportSample.styleCss"
      v-show="showReport.reportSample.show"
    >
      <van-icon name="label-o" size="16" />
      报告样例
    </div>

    <div
      class="itembox one"
      @click="Reportquery"
      :style="showReport.myReport.styleCss"
      v-show="showReport.myReport.show"
    >
      <van-icon name="label-o" size="16" />
      我的报告
    </div>
    <div
      class="itembox two"
      @click="customerService"
      :style="showReport.service.styleCss"
      v-show="showReport.service.show"
    >
      <van-icon name="service" size="16" />
      在线客服
    </div>
    <div
      class="itembox threes"
      @click="show = true"
      :style="showReport.suggest.styleCss"
      v-show="showReport.suggest.show"
    >
      <van-icon name="smile-comment-o" size="16" />
      投诉建议
    </div>

    <!-- 遮罩层 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <input
            type="text"
            name=""
            id=""
            placeholder="请输入姓名"
            v-model="userName"
          />
          <input
            type="tel"
            name=""
            id=""
            placeholder="请输入手机号"
            maxlength="11"
            v-model="userPhone"
          />
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            maxlength="200"
            placeholder="请输入您的建议与反馈"
            v-model="userProposal"
          >
          </textarea>
          <button @click="sendOpinion">提交建议反馈</button>
        </div>
        <div class="close">
          <van-icon name="close" size="30" color="#fff" @click="show = false" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { Overlay, Toast } from 'vant';
import { complaint } from '../api/getUserInfo';
Vue.use(Overlay);
export default {
  data() {
    return {
      show: false,
      userName: null,
      userPhone: null,
      userProposal: null,
      cid: '',
    };
  },
  props: {
    showReport: {
      // 报告样例
      reportSample: {
        show: {
          type: Boolean, //指定值类型boolean
          required: true, //是否为必传项
          default: true, //默认值，如没有父组件没有传递，则默认报告样例为true
        },
        styleCss: {
          type: String,
          required: false,
          default: 'four',
        },
      },
      // 我的报告
      myReport: {
        show: {
          type: Boolean,
          required: true,
          default: true,
        },
        styleCss: {
          type: String,
          required: false,
          default: 'one', //默认值
        },
      },
      // 在线客服
      service: {
        show: {
          type: Boolean,
          required: true,
          default: true,
        },
        styleCss: {
          type: String,
          required: false,
          default: 'two', //默认值
        },
      },
      // 投诉建议
      suggest: {
        show: {
          type: Boolean,
          required: true,
          default: true,
        },
        styleCss: {
          type: String,
          required: false,
          default: 'three', //默认值
        },
      },
    },
  },
  mounted() {
    this.cid = this.$route.params.cid;
    console.log(this.$route.params.cid, '///');
  },
  methods: {
    // 投诉建议
    sendOpinion() {
      // 姓名正则
      let userNameReg = /^[\u4e00-\u9fa5]{2,5}$/;
      // 手号码正则
      let userPhoneUser =
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      // 意见不为空
      if (!userNameReg.test(this.userName)) {
        return Toast('请检查所填写的姓名');
      }
      if (!userPhoneUser.test(this.userPhone)) {
        return Toast('请检查手机号格式');
      }
      if (!this.userProposal) {
        return Toast('投诉反馈意见不能为空');
      }

      // 这里写axios请求逻辑
      complaint({
        username: this.userName,
        mobile: this.userPhone,
        content: this.userProposal,
      })
        .then((res) => {
          if (res.data.code == 200) {
            Toast(res.data.msg);
            this.show = false;
            this.userName = '';
            this.userPhone = '';
            this.userProposal = '';
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    //在线客服
    customerService() {
      this.$router.push({
        path: '/customerService',
        query: {
          cid: this.cid,
        },
      });
    },
    // 我的报告查询
    Reportquery() {
      this.$router.push({
        path: '/Reportquery',
        query: {
          cid: this.cid,
        },
      });
    },
    // 报告样例
    sampleReport() {
      this.$router.push({
        path: '/example',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.itembox {
  width: 22vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 26px;
  margin-bottom: 30px;
  border-top-left-radius: 5.33333vw;
  border-bottom-left-radius: 5.33333vw;
  color: #fff;
  padding: 5px;
}
.one {
  background: linear-gradient(-90deg, #ff5427, #00bcd4);
  box-shadow: 0 0.8vw 2.66667vw 0 #ff7f1c;
}
.two {
  background: linear-gradient(-90deg, #449afb, #0060cd);
  box-shadow: 0 0.8vw 2.66667vw 0 #0060cd;
}
.threes {
  background: linear-gradient(-90deg, #ffca28, #ff9d19);
  box-shadow: 0 0.8vw 2.66667vw 0 #ff9d19;
}
.four {
  background: linear-gradient(-90deg, #ff5427, #ff841b);
  box-shadow: 0 0.8vw 2.66667vw 0 #ff7f1c;
}
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.block {
  width: 66%;
  height: 550px;
  background-color: #fff;
  border-radius: 25px;
  padding: 5% 8%;
  input {
    width: 100%;
    height: 75px;
    margin-bottom: 20px;
    background: #f0f0f0;
    color: #323233;
    border: none;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 1em;
  }
  textarea {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 24px;
    background: #f0f0f0;
    color: #323233;
    border: none;
  }
}
.close {
  margin-top: 20px;
}
button {
  width: 100%;
  height: 80px;
  color: white;
  background: linear-gradient(0deg, rgb(68, 154, 251) 0%, rgb(0, 96, 205) 100%);
  font-size: 28px;
  margin-top: 50px;
  border-radius: 50px;
  outline: none;
  border: none;
}
textarea {
  padding: 15px 5px;
}
</style>
