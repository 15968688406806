<template>
    <div>
        <van-notice-bar left-icon="" :scrollable="false" background="rgba(0,0,0,0); ">
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
        >
            <van-swipe-item v-for="(item,index) in xxdd" :key="index">
            <div class="swiptimgbox">
                <img :src="item.img" alt="" />{{item.text}}
            </div>
            </van-swipe-item>
        </van-swipe>
        </van-notice-bar>
    </div>
</template>

<script>
import Vue from 'vue';
import { Field ,Toast , NoticeBar} from 'vant';
Vue.use(Field);
Vue.use(Toast);
Vue.use(NoticeBar);

export default {
    data(){
        return {
            xxdd:[
                {img:require("../assets/Home2/avatar1.png"),text:'1分钟前有人已购买'},
                {img:require("../assets/Home2/avatar2.png"),text:'46s前有人已购买'},
                {img:require("../assets/Home2/avatar3.png"),text:'23s前有人已购买'},
                {img:require("../assets/Home2/avatar4.png"),text:'8s前有人已购买'}
            ],
        }
    }
}
</script>

<style lang="less" scoped>
        .van-notice-bar{
            background-color:rgba(0,0, 0, 0) ;
        }
        // xx秒前已定位
        .notice-swipe {
            min-width: 300px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: rgba(red, green, blue, 0);
        }
        .swiptimgbox{
            height: 40px;
            line-height: 40px;
            display: flex;
            // align-items: center;
            justify-content: flex-start;
            img{
                margin-right:10px;
                width: 40px;
                height: 40px;
            }
        }

</style>