/*
 * @Author: 申馨
 * @Date: 2023-08-22 09:32:23
 * @LastEditors: 申馨
 * @LastEditTime: 2023-12-12 15:20:45
 * @Description: file content
 * @FilePath: \hongan-h5\src\utils\request.js
 */
// 引入axios
import axios from 'axios';
import router from '@/router';
// import { Toast } from 'vant';
// 创建axios实例
const request = axios.create({
  // 基础路径
  baseURL: '/h5',//正式环境 测试环境都使用同一个
  // 请求超时时间
  // timeout:10000
});
// Toast.loading({
//   message: '加载中...',
//   forbidClick: true,
// });
// 拦截器一般就干两件事
// 1、修改报文（配置项）
// 2、添加额外的功能

// 添加请求拦截器
request.interceptors.request.use(
  // 在发送请求之前做些什么
  (config) => {
    // console.log(config);
    return config;
  },
  // 对请求错误做些什么
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
// request.interceptors.response.use(function (response) {
//     // 对响应数据做点什么
//     return response;
//   }, function (error) {
//     // 对响应错误做点什么
//     return Promise.reject(error);
//   });

request.interceptors.response.use(
  // 对响应数据做点什么
  (response) => {
    // Toast.clear();
    // if (response.data.code == 500) {
    //   //   Toast(response.data.msg);
    //   return response;
    // } else
    if (response.data.code == 5005) {
      setTimeout(() => {
        router.push({
          path: '/',
        });
        // alert(response.data.msg)
      }, 1000);
    } else {
      return response;
    }
  },
  // 对响应错误做点什么
  (err) => {
    return Promise.reject(err);
  }
);

export default request;
